.fern-logo {
  border: 0;
  width: 100%;
  max-height: 100vh;
}

.fern-footer{
  position: fixed;
  bottom:0;
  right:0;
  margin:40px;
  text-align: right;
  pointer-events: all;
}

.fern{
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100%;
  /* mix-blend-mode: hue; */
}

.fern-link{
  font-family: "PPNeueMachina";
  font-size:22px;
  color:#EA9C88;
  text-decoration: none;
  font-weight: bold;
  margin-left:20px;
}

#fern-video{
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(./frame.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

.hide-bg{
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: #ea9c88;
  opacity: 0.3;
  mix-blend-mode: hue;
  pointer-events: none;
}

.cursor-bg{
  position: fixed;
  top: 0;
  left: 0;

  width:20vw;
  height:20vw;

  background-color: #731f9b;
  opacity: 0.9;
  mix-blend-mode: difference;
  border-radius: 50%;
  pointer-events: none;
}

.cursor-bg2{
  position: fixed;
  top: 0;
  left: 0;

  width:20vw;
  height:20vw;

  background-color: #7d00b8;
  opacity: 0.9;
  mix-blend-mode: difference;
  border-radius: 50%;
  pointer-events: none;
}

.fern-small{
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 250px;
  width: 100%;
}