body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #04404C;

  /* overflow: hidden; */
}

@font-face {
  font-family: 'PPNeueMachina';
  src: url("/src/fonts/PPNeueMachina-PlainRegular.woff2");
  font-weight: normal;
}

@font-face {
  font-family: 'PPNeueMachina';
  src: url("/src/fonts/PPNeueMachina-PlainBold.woff2");
  font-weight: bold;
}

#root{
  min-height:100vh;
  position: absolute;
}

#apartmentIframeAll {
  display: none;
  z-index:2;
  position: relative;
  top:0;
  width: 100vw;
  /* height: 100vh; */
  margin-bottom: 100px;
}

#booking-tool-iFrame-allApartments{
  margin: auto;
}

.smoobu-booking-tool-container{
  display: flex;
}

.border-box{
  background-color: #FFFFFF;
}

#smoobuApartment1374047pl{
  display: none;
}

.calendarWidget{
  position: relative;
  display: none;
  max-width: 1000px;
  width: 100%;
}

.calendarContent{
 
}

.calendarContent > div{
  /* margin: auto !important; */
}

.bookingContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 250px;
}